import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { IHistoryData } from '../../UI/Interfaces';
import { postData } from '../../api';
import { slots } from './SlotsMap/slotsMatrix';

interface SummaryContextType {
    historyData: IHistoryData[];
    setShowConflictAlert: (value: boolean) => void;
    fetchData: () => void;
    setShowSuccessAlert: (value: boolean) => void;
    setShowFormWindow?: (value: boolean) => void;
    setShowSummary: (value: boolean) => void;
    setShowMissingDataAlert?: (value: boolean) => void;
}

export const useSummaryData = ({
    historyData,
    setShowConflictAlert,
    fetchData,
    setShowSuccessAlert,
    setShowFormWindow,
    setShowSummary,
    setShowMissingDataAlert
}: SummaryContextType) => {
    const [slot, setSlot] = useState('1');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [plateNumber, setPlateNumber] = useState('');
    const [selectedLevel, setSelectedLevel] = useState(null);
    const [selectedRoom, setSelectedRoom] = useState('not_selected');
    const [startTime, setStartTime] = useState(dayjs(new Date()));
    const [endTime, setEndTime] = useState(dayjs(startTime).add(24, 'hour'));
    const [updatedSlots, setUpdatedSlots] = useState(slots);
    const [requestData, setRequestData] = useState({
        selectedSlot: slot,
        name: name,
        surname: surname,
        plateNumber: plateNumber,
        room: selectedRoom,
        startTime: startTime.format('DD.MM.YYYY HH:MM'),
        endTime: endTime.format('DD.MM.YYYY HH:MM')
    });
    const [isToday, setIsToday] = useState(dayjs(startTime).isSame(dayjs(), 'day'));

    useEffect(() => {
        const isToday = dayjs(startTime).isSame(dayjs(), 'day');
        setIsToday(isToday);
    }, [startTime]);

    useEffect(() => {
        setRequestData({
            selectedSlot: slot,
            name: name,
            surname: surname,
            plateNumber: plateNumber,
            room: selectedRoom,
            startTime: startTime.format('DD.MM.YYYY HH:MM'),
            endTime: endTime.format('DD.MM.YYYY HH:MM')
        });
    }, [slot, name, surname, plateNumber, selectedRoom, startTime, endTime]);

    const checkConflictAndSubmit = async () => {
        if (!requestData) return;

        const startTimeStr = dayjs(requestData.startTime.toString()).format('YYYY-MM-DDTHH:mm:ss');
        const endTimeStr = dayjs(requestData.endTime.toString()).format('YYYY-MM-DDTHH:mm:ss');
        const conflict = historyData.some((record) => {
            const recordStartTime = dayjs(record.time_from);
            const recordEndTime = dayjs(record.time_to);
            const isTimeConflict =
                (recordStartTime.isBefore(startTimeStr) && recordEndTime.isAfter(startTimeStr)) ||
                (recordStartTime.isBefore(endTimeStr) && recordEndTime.isAfter(endTimeStr)) ||
                recordStartTime.isSame(startTimeStr) ||
                recordEndTime.isSame(endTimeStr);

            const isSlotConflict = record.slot === requestData.selectedSlot;

            return isTimeConflict && isSlotConflict;
        });

        if (conflict) {
            setShowConflictAlert(true);
            if (setShowFormWindow) setShowFormWindow(false);
            return;
        }

        const postDataResponse = await postData({
            ID: requestData.selectedSlot,
            full_name: requestData.name + ' ' + requestData.surname,
            room_number: requestData.room,
            plate_number: requestData.plateNumber,
            time_from: startTimeStr,
            time_to: endTimeStr
        });

        fetchData();
        console.log(postDataResponse);
        if (postDataResponse.status === 200) {
            // Handle success
            setShowSuccessAlert(true);
        }
    };

    const displaySummary = () => {
        const data =
            requestData.name !== '' &&
            requestData.surname !== '' &&
            requestData.plateNumber !== '' &&
            requestData.room !== null &&
            requestData.startTime !== null &&
            requestData.endTime !== null &&
            requestData.selectedSlot !== null
                ? true
                : false;
        return data;
    };

    const onSubmit = async () => {
        const startTimeStr = dayjs(startTime.format('YYYY-MM-DDTHH:mm:ss'));
        const endTimeStr = dayjs(endTime.format('YYYY-MM-DDTHH:mm:ss'));
        const conflict = historyData.some((record) => {
            const recordStartTime = dayjs(record.time_from);
            const recordEndTime = dayjs(record.time_to);
            const isTimeConflict =
                (recordStartTime.isBefore(startTimeStr) && recordEndTime.isAfter(startTimeStr)) ||
                (recordStartTime.isBefore(endTimeStr) && recordEndTime.isAfter(endTimeStr)) ||
                recordStartTime.isSame(startTimeStr) ||
                recordEndTime.isSame(endTimeStr);

            const isSlotConflict = record.slot === slot;

            return isTimeConflict && isSlotConflict;
        });

        if (conflict) {
            setShowConflictAlert(true);
            if (setShowFormWindow) setShowFormWindow(false);
            return;
        }

        const postDataResponse = await postData({
            ID: slot,
            full_name: name + ' ' + surname,
            room_number: selectedRoom,
            plate_number: plateNumber,
            time_from: startTimeStr,
            time_to: endTimeStr
        });
        if (postDataResponse?.error) {
            console.log('Api says: ', postDataResponse.error);
        }
        fetchData();
        setShowSuccessAlert(true);
        if (setShowFormWindow) {
            setShowFormWindow(false);
        }
        if (setShowMissingDataAlert && postDataResponse === undefined) {
            setShowMissingDataAlert(true);
        }
        if (postDataResponse && postDataResponse.status === 200) {
            // Handle success
        }
    };

    const onCheckSubmition = () => {
        const display = displaySummary();
        setShowSummary(display);
        if (setShowFormWindow) {
            setShowFormWindow(!display);
        }
    };

    return {
        checkConflictAndSubmit,
        slot,
        setSlot,
        name,
        setName,
        surname,
        setSurname,
        selectedLevel,
        setSelectedLevel,
        selectedRoom,
        setSelectedRoom,
        startTime,
        setStartTime,
        endTime,
        setEndTime,
        plateNumber,
        setPlateNumber,
        displaySummary,
        onSubmit,
        onCheckSubmition,
        requestData, // return requestData here
        updatedSlots,
        setUpdatedSlots,
        isToday
    };
};
