import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { avaliabilityColors } from '../../../UI/Thene';
import { IDeleteData, ITableCalendarProps } from '../../../UI/Interfaces';
import { StyledTableCell, StyledTableRow } from './tableCalendar.tss';
import { deleteRecord } from '../../../api';
import { Button, Tooltip } from '@mui/material';
import React from 'react';
import { useSummaryData } from '../useSummaryData';
import { buttonStyleSend } from '../../Page.jss';
import OccupiedPopUp from '../PopUps/Occupied/occupiedPopUp';
import NonOccupiedPopUp from '../PopUps/Non-Occupied/nonOccupiedPopUp';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

function createData(parkingSpace: string, dates: string[]) {
    return { parkingSpace, dates };
}

const toolTipStyle = {
    color: 'white',
    backgroundColor: '#3A3B3C',
    borderRadius: '10px',
    padding: '10px',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 15000
};

const today = dayjs(new Date());
const dateFormat = 'DD.MM.YYYY';

export default function TableCalendar(props: ITableCalendarProps) {
    const {
        dateFrom,
        data,
        futureOccupancy,
        setShowDeleteSuccessAlert,
        slotsData,
        historyData,
        setShowSuccessAlert,
        setShowConflictAlert,
        fetchData,
        setCurrentSlot,
        setCurrentDate,
        setShowFormWindow,
        setShowSummary
    } = props;

    const [allData, setAllData] = useState(data.concat(futureOccupancy) || {});
    const [clickedCell, setClickedCell] = useState({ slot: '', date: '' });

    const { setSlot, setStartTime } = useSummaryData({
        historyData,
        setShowConflictAlert,
        fetchData,
        setShowSuccessAlert,
        setShowFormWindow,
        setShowSummary
    });

    useEffect(() => {
        setAllData(data.concat(futureOccupancy));
    }, [data, futureOccupancy]);

    useEffect(() => {
        setSlot(clickedCell.slot);
        setStartTime(dayjs(clickedCell.date, dateFormat));
    }, [clickedCell]);

    function generateDates(dateFrom: dayjs.Dayjs | null) {
        if (!dateFrom) return [];
        return Array.from({ length: 14 }, (_, index) =>
            dateFrom
                ? dayjs(dateFrom).add(index, 'day').format(dateFormat)
                : today.add(index, 'day').format(dateFormat)
        );
    }

    const rows = Array.from({ length: 32 }, (_, rowIndex) =>
        createData(`${rowIndex + 1}`, generateDates(dateFrom))
    );

    const asyncDeleteRecord = async (record: IDeleteData) => {
        setShowDeleteSuccessAlert(true);
        try {
            const isoDate = dayjs(record.time, 'DD.MM.YYYY').toISOString();
            const deleteData = await deleteRecord({ slot: record.slot, time: isoDate });

            if (deleteData && deleteData.status === 200) {
                setShowDeleteSuccessAlert(true);
                const updatedData = allData.filter(
                    (slot) =>
                        !(
                            slot.slot === record.slot &&
                            dayjs(slot.time_from).format('DD.MM.YYYY') === record.time
                        )
                );
                setAllData(updatedData);
            } else {
                console.error('Failed to delete record', deleteData);
            }
        } catch (error) {
            console.error('Error deleting record:', error);
        }
    };

    const getOccupiedSlotData = (slot: string, date: string) => {
        const datesOfOccupancy = getTimeOfOccupancy(slot, date);
        const relevantSlotsData = slotsData.filter((slotData) => slotData.ID === slot);
        const relevantHistoryData = historyData.filter((history) => {
            return (
                history.slot === slot &&
                (dayjs(history.time_from).format(dateFormat) <= date ||
                    dayjs(history.time_to).format(dateFormat) >= date)
            );
        });

        const data = {
            datesOfOccupancy: datesOfOccupancy,
            timeOfOccupancy: getTimeOfOccupancy(slot, date),
            slotsData: relevantSlotsData,
            historyData: relevantHistoryData
        };

        return data;
    };

    const getTimeOfOccupancy = (
        slot: string,
        date: string | number | Date | dayjs.Dayjs | null | undefined
    ) => {
        const occupiedSlots = allData.filter((occupiedSlot) => occupiedSlot.slot === slot);
        let timeOfOccupancy = '';
        let isFromAdded = false;
        let isToAdded = false;

        for (const occupiedSlot of occupiedSlots) {
            const slotFrom = dayjs(occupiedSlot.time_from);
            const slotTo = dayjs(occupiedSlot.time_to);
            const currentDate = dayjs(date, dateFormat);

            if (
                currentDate.isSameOrBefore(slotTo, 'day') &&
                currentDate.isSameOrAfter(slotFrom, 'day')
            ) {
                if (currentDate.isSame(slotFrom, 'day') && !isFromAdded) {
                    timeOfOccupancy += `Od: ${slotFrom.format('HH:mm')}\n`;
                    isFromAdded = true;
                }
                if (
                    currentDate.isSame(slotTo, 'day') &&
                    !currentDate.isSame(slotFrom, 'day') &&
                    !isToAdded
                ) {
                    timeOfOccupancy += `Do: ${slotTo.format('HH:mm')}\n`;
                    isToAdded = true;
                } else if (!isFromAdded && !isToAdded) {
                    timeOfOccupancy += `Occupied\n`;
                }
            }
        }
        return timeOfOccupancy.trim();
    };

    const handleCellClick = (slot: string, date: string) => {
        setCurrentSlot(slot);
        setCurrentDate(date);
        setShowFormWindow(true);
        if (clickedCell.slot === slot && clickedCell.date === date) {
            setClickedCell({ slot: '', date: '' });
        } else {
            setClickedCell({ slot, date });
        }
    };

    const sameDayMatchingSlots = (slot: string, date: string) => {
        return data
            .filter((record1) => {
                const timeTo1 = dayjs(record1.time_to);
                return (
                    record1.slot === slot &&
                    data.some((record2) => {
                        const timeFrom2 = dayjs(record2.time_from);
                        return record1.slot === record2.slot && timeTo1.isSame(timeFrom2, 'day');
                    })
                );
            })
            .filter((record) => record.slot === slot);
    };

    const isDataToDisplayValid = (info: any, row: any, date: string): boolean => {
        const timeFromFormatted = dayjs(info.time_from).format(dateFormat);
        const timeToFormatted = dayjs(info.time_to).format(dateFormat);
        const resultData =
            info.slot === row.parkingSpace &&
            ((timeFromFormatted <= date && timeToFormatted >= date) ||
                timeFromFormatted === date ||
                timeToFormatted === date ||
                date.startsWith('01.') ||
                date.startsWith('02.') ||
                date.startsWith('03.') ||
                date.startsWith('04.'));
        return resultData;
    };

    return (
        <div className="content-container">
            <TableContainer component={Paper}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Parkovací místo</StyledTableCell>
                            {generateDates(dateFrom).map((date, index) => (
                                <StyledTableCell key={index}>{date}</StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.parkingSpace}>
                                <StyledTableCell component="th" scope="row">
                                    {row.parkingSpace === '32' ? 'R99' : row.parkingSpace}
                                </StyledTableCell>
                                {row.dates.map((date, index) => {
                                    const isOccupied = allData.some((slot) => {
                                        const slotFrom = dayjs(slot.time_from);
                                        const slotTo = dayjs(slot.time_to);
                                        const currentDate = dayjs(date, dateFormat);
                                        return (
                                            slot.slot === row.parkingSpace &&
                                            currentDate.isSameOrAfter(slotFrom, 'day') &&
                                            currentDate.isSameOrBefore(slotTo, 'day')
                                        );
                                    });

                                    const dataOfReservation = getOccupiedSlotData(
                                        row.parkingSpace,
                                        date
                                    );
                                    const timeOfOccupancy = getTimeOfOccupancy(
                                        row.parkingSpace,
                                        date
                                    );

                                    let cellContent;
                                    if (isOccupied) {
                                        cellContent = OccupiedPopUp(
                                            row,
                                            date,
                                            timeOfOccupancy,
                                            dataOfReservation,
                                            timeOfOccupancy.includes('Do:'),
                                            dataOfReservation.historyData,
                                            sameDayMatchingSlots,
                                            isDataToDisplayValid,
                                            asyncDeleteRecord,
                                            handleCellClick,
                                            toolTipStyle
                                        );
                                    } else {
                                        cellContent = NonOccupiedPopUp(
                                            row,
                                            date,
                                            timeOfOccupancy,
                                            index,
                                            handleCellClick,
                                            toolTipStyle,
                                            setSlot,
                                            clickedCell
                                        );
                                    }

                                    return cellContent;
                                })}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
