import React from "react";
import Alert, { AlertColor } from "@mui/material/Alert";
import { buttonStyle } from "../../Page.jss";

interface AlertPageProps {
  title: string;
  type: string;
  onClick: () => void;
  buttonTitle: string;
}

const AlertPage: React.FC<AlertPageProps> = ({
  title,
  type,
  onClick,
  buttonTitle,
}) => {
  return (
    <div className="alert">
      <Alert severity={type as AlertColor}>{title}</Alert>
      <button onClick={() => onClick()} style={buttonStyle}>
        <p style={{ color: "white" }}>{buttonTitle}</p>
      </button>
    </div>
  );
};

export default AlertPage;
