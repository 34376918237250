import './App.css';
import { IDeleteData } from './UI/Interfaces';

const urlCore = 'https://www.budweis-parking.briskark.sk';
// const urlCore = 'http://localhost:10010';

function Api() {}

export default Api;

// export const getAllSlots = async () => {
//   try {
//     const response = await fetch(urlCore + "/get-all-slots");
//     if (!response.ok) {
//       throw new Error("Network response was not ok");
//     }
//     return await response.json();
//   } catch (error) {
//     console.error("/get-all-slots: " + error);
//   }
// };

export const getHistoryRecords = async () => {
    try {
        const response = await fetch(urlCore + '/get-slot-history');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('/get-slot-history: ' + error);
    }
};

export const postData = async (data: any) => {
    try {
        const response = await fetch(urlCore + '/create-reservation', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        if (!response.ok) {
            throw new Error('Network response was not ok. Status code: ' + response.status);
        }
        return await response.json();
    } catch (error) {
        console.error('/create-reservation: ' + error);
    }
};

export const deleteRecord = async (data: IDeleteData) => {
    try {
        const { slot, time } = data;
        const response = await fetch(`${urlCore}/delete-reservation/${slot}/${time}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('/delete-reservation: ' + error);
    }
};
