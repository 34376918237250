import { Typography, Tooltip, Button } from '@mui/material';
import React from 'react';
import { avaliabilityColors } from '../../../../UI/Thene';
import { buttonStyleSend } from '../../../Page.jss';
import { toolTipContentStyle, StyledTableCell } from '../../TableCalendar/tableCalendar.tss';

const NonOccupiedPopUp = (
    row: { parkingSpace: string; dates?: string[] },
    date: string,
    timeOfOccupancy: string,
    index: number,
    handleCellClick: (slot: string, date: string) => void,
    toolTipStyle: any,
    setSlot: (slot: string) => void,
    clickedCell: any
    // firstFourDays?: boolean
) => {
    return (
        <Tooltip
            title={
                <React.Fragment>
                    <h2>{clickedCell.date}</h2>
                    <h2>
                        Dostupné parkovací místo{' '}
                        {row.parkingSpace === '32' ? 'R99' : row.parkingSpace}
                    </h2>
                    <Button
                        style={buttonStyleSend}
                        variant="contained"
                        color="error"
                        onClick={() => handleCellClick(row.parkingSpace, date)}
                    >
                        Vytvořit rezervaci
                    </Button>
                </React.Fragment>
            }
            onClick={() => {
                setSlot(row.parkingSpace);
            }}
            placement="top"
            arrow
            componentsProps={{
                tooltip: {
                    sx: toolTipStyle
                }
            }}
        >
            <StyledTableCell
                key={index}
                align="right"
                style={{
                    backgroundColor: avaliabilityColors.available,
                    border: '1px solid black'
                }}
            >
                {timeOfOccupancy}
            </StyledTableCell>
        </Tooltip>
    );
};

export default NonOccupiedPopUp;
