import { SetStateAction, useEffect, useState } from 'react';
import FooterContainer from '../Footer/FooterContainer/footerContainer';
import Header from '../Header/Header';
import BackgroundImage from '../assets/background01.jpeg';
import './Page.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { reservationhistoryStyle } from './Page.jss';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { getHistoryRecords } from '../api';
import { IHistoryData, IPageProps, ISlotsData } from '../UI/Interfaces';
import TableCalendar from './components/TableCalendar/tableCalendar';
import AlertPage from './components/AlertPage/alerPage';
import { useSummaryData } from './components/useSummaryData';
import ReservationForm from './components/ReservationForm/reservationForm';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const Page = ({ slotsData, historyData, setHistoryData }: IPageProps) => {
    const [dateFrom, setDatefrom] = useState(dayjs(new Date()));
    const [futureOccupiedSlots, setFutureOccupiedSlots] = useState([]);
    const [showConflictAlert, setShowConflictAlert] = useState(false);
    const [showMissingDataAlert, setShowMissingDataAlert] = useState(false);
    const [showSuccesstAlert, setShowSuccessAlert] = useState(false);
    const [showDeleteSuccessAlert, setShowDeleteSuccessAlert] = useState(false);
    const [currentSlot, setCurrentSlot] = useState<string | null>(null);
    const [currentDate, setCurrentDate] = useState<string | null>(null);
    const [showFormWindow, setShowFormWindow] = useState(false);
    const [showSummary, setShowSummary] = useState(false);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);

        if (windowDimensions.width < 768) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
        return () => window.removeEventListener('resize', handleResize);
    }, [windowDimensions, isMobile]);

    const fetchData = async () => {
        try {
            const historyData = await getHistoryRecords();
            setHistoryData(historyData);
        } catch (error) {
            console.error('Error fetching data during initialization phase: ', error);
        }
    };

    const { updatedSlots, setUpdatedSlots } = useSummaryData({
        historyData,
        setShowConflictAlert,
        fetchData,
        setShowSuccessAlert,
        setShowFormWindow,
        setShowSummary,
        setShowMissingDataAlert
    });

    const onConfirmNewSelection = () => {
        setShowConflictAlert(false);
        setShowSuccessAlert(false);
        setShowDeleteSuccessAlert(false);
        setShowSummary(false);
        setShowFormWindow(false);
    };

    const filterSlots = (slotsData: ISlotsData[], historyData: IHistoryData[]) => {
        const currentOccupiedData: string[] = [];
        const futureOccupiedData: IHistoryData[] = [];

        slotsData.forEach((slot) => {
            const matchingHistoryRecords = historyData.filter(
                (record) => record.slot === String(slot.ID)
            );

            if (matchingHistoryRecords.length > 0) {
                const currentTime = dayjs();
                matchingHistoryRecords.forEach((record) => {
                    const timeFrom = dayjs(record.time_from);
                    const timeTo = dayjs(record.time_to);
                    if (currentTime.isAfter(timeFrom) && currentTime.isBefore(timeTo)) {
                        currentOccupiedData.push(slot.ID.toString());
                    }
                    if (currentTime.isBefore(timeFrom)) {
                        futureOccupiedData.push(record);
                    }
                });
            }
        });
        return { currentOccupiedData, futureOccupiedData };
    };

    useEffect(() => {
        fetchData();
    }, [showDeleteSuccessAlert]);

    useEffect(() => {
        const result = filterSlots(slotsData, historyData); //MARK: Filtering slots
        const filteredSlots = updatedSlots.filter(
            (slot) => !result.currentOccupiedData.includes(slot.value)
        );
        setUpdatedSlots(filteredSlots);
        setFutureOccupiedSlots(result.futureOccupiedData as never[]);
    }, [historyData, slotsData]);
    console.log('isMobile', isMobile);
    return (
        <>
            <div className="main-wrapper">
                <Header isMobile={isMobile} />
                <div className={`${isMobile ? 'mobile-wrapper' : 'content-wrapper'}`}>
                    <img
                        src={BackgroundImage}
                        alt="hotel-backgroud"
                        className="image-background" //MARK: Background image
                    />
                    {showConflictAlert && (
                        <AlertPage
                            title={
                                'V zvoleném časovém rozmezí existuje konflikt. Prosím, zvolte jiný čas nebo místo.'
                            }
                            type={'error'}
                            onClick={onConfirmNewSelection}
                            buttonTitle={'Ok, zvolím jiný čas nebo místo'}
                        />
                    )}
                    {showMissingDataAlert && (
                        <AlertPage
                            title={
                                'Chybí některá data pro rezervaci. Prosím, vyplňte všechna pole.'
                            }
                            type={'error'}
                            onClick={() => {
                                setShowMissingDataAlert(false);
                                setShowFormWindow(true);
                            }}
                            buttonTitle={'Ok, vyplním data'}
                        />
                    )}
                    {showSuccesstAlert && !showMissingDataAlert && (
                        <AlertPage
                            title={'Rezervace byla úspěšně vytvořena.'}
                            type={'success'}
                            onClick={onConfirmNewSelection}
                            buttonTitle={'Super! Zpět na přehled'}
                        />
                    )}
                    {showDeleteSuccessAlert && (
                        <AlertPage
                            title={'Vybraná rezervace byla zrušena.'}
                            type={'success'}
                            onClick={onConfirmNewSelection}
                            buttonTitle={'Děkuji! Zpět na přehled'}
                        />
                    )}
                    {showFormWindow && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100vh',
                                top: '50%',
                                marginTop: '60px'
                            }}
                        >
                            <ReservationForm
                                slotsData={slotsData}
                                historyData={historyData}
                                setHistoryData={setHistoryData}
                                setShowSuccessAlert={setShowSuccessAlert}
                                setFutureOccupiedSlots={(
                                    value: boolean | SetStateAction<never[]>
                                    //@ts-ignore
                                ) => setFutureOccupiedSlots(value)}
                                setShowConflictAlert={setShowConflictAlert}
                                showDeleteSuccessAlert={showDeleteSuccessAlert}
                                fetchData={fetchData}
                                reservationSlot={currentSlot || ''} // Ensure reservationSlot is always a string
                                date={currentDate as string}
                                setShowFormWindow={setShowFormWindow}
                                setShowSummary={setShowSummary}
                                onConfirmNewSelection={onConfirmNewSelection}
                                setShowMissingDataAlert={setShowMissingDataAlert}
                            />
                        </div>
                    )}
                    {!showConflictAlert &&
                        !showSuccesstAlert &&
                        !showDeleteSuccessAlert &&
                        !showFormWindow && (
                            <div>
                                {!showSummary && (
                                    <div>
                                        <div style={reservationhistoryStyle}>
                                            <h2>Historie rezervací</h2>
                                            <DatePicker
                                                label="Dátum od"
                                                value={dayjs(dateFrom) ?? dayjs(new Date())}
                                                onChange={(newValue: Dayjs | null) => {
                                                    setDatefrom(dayjs(newValue) ?? null);
                                                }}
                                                disablePast
                                                //@ts-ignore
                                                format="DD/MM/YYYY"
                                            />
                                        </div>
                                        <TableCalendar
                                            futureOccupancy={futureOccupiedSlots}
                                            dateFrom={dayjs(dateFrom)}
                                            data={historyData}
                                            setShowDeleteSuccessAlert={setShowDeleteSuccessAlert}
                                            //for reservation form
                                            slotsData={slotsData}
                                            historyData={historyData}
                                            setHistoryData={setHistoryData}
                                            setShowSuccessAlert={setShowSuccessAlert}
                                            //@ts-ignore
                                            setFutureOccupiedSlots={setFutureOccupiedSlots}
                                            setShowConflictAlert={setShowConflictAlert}
                                            showDeleteSuccessAler={showDeleteSuccessAlert}
                                            fetchData={fetchData}
                                            setCurrentSlot={setCurrentSlot}
                                            setCurrentDate={setCurrentDate}
                                            setShowFormWindow={setShowFormWindow}
                                            onConfirmNewSelection={onConfirmNewSelection}
                                            setShowMissingDataAlert={setShowMissingDataAlert}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                </div>
                <FooterContainer />
            </div>
        </>
    );
};

export default Page;
