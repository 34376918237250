import React from 'react';
import './textForm.css';

interface TextFormProps {
    title: string;
    onChange: (text: string) => void;
    useUpperCase?: boolean;
}

const TextForm: React.FC<TextFormProps> = ({ title, onChange, useUpperCase = false }) => {
    const toInputUppercase = (e: React.FormEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        target.value = target.value.toUpperCase();
    };

    return (
        <div className="text-form-container">
            {/* <p className="title">{title}</p> */}
            <input
                type="text"
                placeholder={title}
                onChange={(e) => onChange(e.target.value)}
                style={{
                    display: 'flex',
                    padding: '10px',
                    textAlign: 'center',
                    justifyContent: 'center',
                    width: '100%'
                }}
                onInput={(e) => (useUpperCase ? toInputUppercase(e) : null)}
            />
        </div>
    );
};

export default TextForm;
