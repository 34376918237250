import { Typography, Tooltip, Button } from '@mui/material';
import React from 'react';
import { avaliabilityColors } from '../../../../UI/Thene';
import { buttonStyleSend } from '../../../Page.jss';
import { toolTipContentStyle, StyledTableCell } from '../../TableCalendar/tableCalendar.tss';

const OccupiedPopUp = (
    row: { parkingSpace: string; dates?: string[] },
    date: string,
    timeOfOccupancy: string,
    dataOfReservation: {
        datesOfOccupancy?: string;
        timeOfOccupancy?: string;
        slotsData?: any[];
        historyData: any[];
    },
    displayDelete: boolean = false,
    reservationInfo: any[],
    sameDayMatchingSlots: (slot: string, date: string) => any[],
    isDataToDisplayValid: (
        reservation: any,
        row: { parkingSpace: string },
        date: string
    ) => boolean,
    asyncDeleteRecord: (data: { slot: string; time: string }) => void,
    handleCellClick: (slot: string, date: string) => void,
    toolTipStyle: any
    // firstFourDays?: boolean
) => {
    const hasFromAndTo =
        (timeOfOccupancy.includes('Do:') && timeOfOccupancy.includes('Od:')) ||
        timeOfOccupancy === 'Occupied';
    const isFromDate = timeOfOccupancy.includes('Od:');

    const timeEntries = timeOfOccupancy.split('\n');
    const formatText = (label: string, value: string) => (
        <Typography color="inherit" key={label + value}>
            {label}: <span style={{ fontWeight: 'lighter' }}>{value}</span>
        </Typography>
    );

    let doubleRecord: boolean | null = null;

    const reservationDetails = timeEntries.map((entry, index) => {
        const entryParts = entry.split(': ');
        const key = entryParts[0];
        const value = entryParts[1];

        const foundMatch = sameDayMatchingSlots(row.parkingSpace, date);

        const isWithinRange = index === timeEntries.length - 1 || (index !== 0 && !hasFromAndTo);
        if (isWithinRange) {
            return (
                <div key={index}>
                    {dataOfReservation.historyData.map((reservation, reservationIndex) => {
                        doubleRecord =
                            foundMatch.length === 2 &&
                            foundMatch[reservationIndex]?.full_name !== reservation?.full_name &&
                            reservationIndex === foundMatch.length;
                        // if (firstFourDays) {
                        //     console.log('Dane dni data su: ', reservation, row, date);
                        // }
                        if (isDataToDisplayValid(reservation, row, date)) {
                            return (
                                <>
                                    <div key={reservationIndex}>
                                        {formatText('Jméno', reservation?.full_name)}
                                        {key === 'Do:'
                                            ? formatText('Do:', value)
                                            : formatText(key, value)}
                                        {formatText(
                                            'Pokoj',
                                            reservation.room_number === 'not_selected'
                                                ? 'Nespecifikováno'
                                                : reservation.room_number
                                        )}
                                        {formatText('SPZ', reservation.plate_number)}
                                        <hr />
                                    </div>
                                </>
                            );
                        }
                        return null;
                    })}
                </div>
            );
        }
        return null;
    });

    const filteredReservations = reservationInfo.filter((info) =>
        isDataToDisplayValid(info, row, date)
    );

    const reservationInfoText =
        filteredReservations.length === 2
            ? filteredReservations[0]?.full_name + ' / ' + filteredReservations[1]?.full_name
            : filteredReservations[0]?.full_name;

    return (
        <Tooltip
            title={
                <React.Fragment>
                    <div style={toolTipContentStyle}>
                        {reservationDetails}
                        {!doubleRecord && (
                            <Button
                                style={{
                                    marginTop: '10px',
                                    textAlign: 'center',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                                variant="contained"
                                color="error"
                                onClick={() =>
                                    asyncDeleteRecord({ slot: row.parkingSpace, time: date })
                                }
                            >
                                Smazat
                            </Button>
                        )}
                        {displayDelete && doubleRecord && (
                            <>
                                <Button
                                    style={{
                                        marginTop: '10px',
                                        textAlign: 'center',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                    variant="contained"
                                    color="error"
                                    onClick={() =>
                                        asyncDeleteRecord({
                                            slot: row.parkingSpace,
                                            time: date
                                        })
                                    }
                                >
                                    Zrušit pozdější rezervaci
                                </Button>
                            </>
                        )}
                        {!hasFromAndTo && !isFromDate && (
                            <Button
                                style={buttonStyleSend}
                                variant="contained"
                                color="error"
                                onClick={() => handleCellClick(row.parkingSpace, date)}
                            >
                                Vytvořit rezervaci
                            </Button>
                        )}
                    </div>
                </React.Fragment>
            }
            placement="top"
            arrow
            componentsProps={{
                tooltip: {
                    sx: toolTipStyle
                }
            }}
        >
            <StyledTableCell
                align="right"
                style={{
                    backgroundColor: avaliabilityColors.occupied,
                    border: '1px solid black'
                }}
            >
                {reservationInfoText}
            </StyledTableCell>
        </Tooltip>
    );
};

export default OccupiedPopUp;
