import React from "react";
import "./Header.css";

const headerTitle = "Rezervační systém podzemního parkoviště";

const Header: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  return (
    <div className="headerContainer">
      {!isMobile &&<img
        src={
          "https://www.hotelbudweis.cz/wp-content/uploads/2022/05/budweis_logo.png"
        }
        alt="hotel-backgroud"
        className="image-logo"
      />}
      <p className="title-style">{headerTitle}</p>
    </div>
  );
};

export default Header;
