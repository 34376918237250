import React from 'react';
import Select from 'react-select';
import './dropdownSelect.css';

interface IDropdownSelectProps {
    title: string;
    initialValue: any;
    onChange: (value: any) => void;
    options: any;
    className?: string;
}

const DropdownSelect: React.FC<IDropdownSelectProps> = ({
    title,
    initialValue,
    onChange,
    options,
    className
}) => {
    return (
        <div style={{ width: '100%', justifyContent: 'center' }}>
            <Select
                defaultValue={initialValue}
                onChange={(value) => {
                    onChange(value);
                }}
                options={options}
                className={className}
            />
        </div>
    );
};

export default DropdownSelect;
