export const inputStyle = {
    display: "flex",
    padding: "10px",
    textAlign: "center",
    justifyContent: "center",
  };

  export const buttonStyle = {
    fontSize: "16px",
    borderRadius: "5px",
    backgroundColor: "black",
    color: "white",
    border: "none",
    cursor: "pointer",
    margin: "5px",
    padding: "5px",
    zIndex: 10,
    top: 300,
  };

  export const buttonStyleSend= {
    fontSize: "16px",
    borderRadius: "5px",
    backgroundColor: "green",
    color: "white",
    border: "none",
    cursor: "pointer",
    margin: "5px",
    padding: "5px",
    // zIndex: 10,
    // top: 300,
  }

  export const reservationhistoryStyle = {
    backgroundColor: "transparent",
    marginBottom: "15px",
    display:"flex",
    alignItems:"center",
    padding:"10px",
    gap:"15px",
    marginTop: "100px",
  };